<!-- src/views/LayoutMain.vue -->

<template>
  <div>
    <!-- Aquí puedes tener elementos comunes como encabezados, menús, etc. -->
    <router-view></router-view> <!-- Este es el espacio donde se cargarán los componentes según las rutas -->
  </div>
</template>

<script>
export default {
  // Puedes agregar lógica o estilos específicos aquí si es necesario
}
</script>

<style>

</style>
